import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggle", "label" ]

  connect() {
    if (localStorage.getItem('lightMode') === 'true') {
      document.documentElement.classList.add('theme--light');
      this.toggleTarget.checked = false;
      this.labelTarget.innerText = 'Light Mode';
    } else {
      document.documentElement.classList.remove('theme--light');
      this.toggleTarget.checked = true;
      this.labelTarget.innerText = 'Dark Mode';
    }

    this.toggleTarget.addEventListener('change', (e) => {
      this.toggleDarkMode();
    });
  }

  toggleDarkMode() {
    document.documentElement.classList.toggle('theme--light')
    localStorage.setItem('lightMode', document.documentElement.classList.contains('theme--light'))
    if (document.documentElement.classList.contains('theme--light')) {
      this.labelTarget.innerText = 'Light Mode';
    } else {
      this.labelTarget.innerText = 'Dark Mode';
    }

    if (isDevEnvironment()) {
        console.info(`[DEV] Theme set to ${document.documentElement.classList.contains('theme--light') ? 'light' : 'light'}`); 
    }
  }
}
