import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown"]

  connect() {
    this.dropdownTargets.forEach((dropdown) => {

      dropdown.addEventListener('mouseenter', (e) => {
        e.preventDefault();
        dropdown.setAttribute('open', '');

        const otherDropdowns = this.dropdownTargets.filter((d) => d !== dropdown);
        otherDropdowns.forEach((d) => {
          d.removeAttribute('open');
        });
      });

      dropdown.addEventListener('mouseleave', (e) => {
        e.preventDefault();
        setTimeout(() => {
          dropdown.removeAttribute('open');
        }, 300);
      });
    });
  }
}
