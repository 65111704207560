import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "content" ]

  connect() {
    this.buttonTarget.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggleBurgerMenu();
    });
  }

  toggleBurgerMenu() {
    this.buttonTarget.classList.toggle('is-active');
    this.contentTarget.classList.toggle('is-open');
  }
}
