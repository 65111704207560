import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class SelectController extends Controller {
  connect() {
    const choices = document.querySelectorAll('select');

    choices.forEach((choice) => {
      if (!choice.classList.contains('flatpickr-monthDropdown-months')) {
        new Choices(choice, {
          searchEnabled: false,
          itemSelectText: '',
        });

        if (isDevEnvironment()) {
          if (choice) {
            console.info(`[DEV] Choices initialized for: `, this.element);
          }
          else {
            console.error(`[DEV] Error in initialization of Choices for element: `, this.element);
          }
        }
      }
    });
  }
}
