import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "button" ];

  static values = {
    notification: String
  }

  connect() {
    this.buttonTarget.addEventListener('click', (e) => {
      e.preventDefault();
      this.copy();
    });
  }

  copy() {
    this.inputTarget.select()
    this.inputTarget.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(this.inputTarget.value);

    dispatchInfoToast(this.notificationValue);
  }
}
